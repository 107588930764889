<template>
  <div>
    <v-row class="mt-3 pt-5">
      <v-col cols="auto" align-self="center">
        <v-btn icon to="/verein" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-connection</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-bold">
          SCHNITTSTELLE GESUNDHEITSAMT
        </h3>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-row justify="center" v-if="locations.length > 0">
        <v-col
          cols="11"
          class="pa-0 mx-0 my-2"
          v-for="(place, index) in locations"
          :key="index"
        >
          <v-card
            class="rounded-pill"
            :color="
              place.active ? template.colors.success : template.colors.danger
            "
            :style="`color: ${
              place.active
                ? template.colors.success_text
                : template.colors.danger_text
            };`"
            @click="
              location = place
              editLocation = true
              tabs = 0
            "
            link
          >
            <v-row justify="center" class="px-2">
              <v-col cols="2" align-self="center">
                <v-avatar class="my-auto" large>
                  <v-icon
                    :style="`background-color: ${
                      place.active
                        ? template.colors.success_text
                        : template.colors.danger_text
                    }; color: ${
                      place.active
                        ? template.colors.success
                        : template.colors.danger
                    };`"
                  >
                    {{ place.active ? 'mdi-connection' : 'mdi-power-plug-off' }}
                  </v-icon>
                </v-avatar>
              </v-col>
              <v-col cols="8" align-self="center">
                <span class="font-weight-bold">
                  {{ place.name }}
                  <br />
                  <small class="font-weight-light">
                    {{ place.active ? 'VERBUNDEN' : 'NICHT VERBUNDEN' }}
                  </small>
                </span>
              </v-col>
              <v-col cols="2" align-self="center" class="text-center">
                <v-icon large>mdi-chevron-right</v-icon>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
    <v-dialog
      v-model="editLocation"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      :persistent="$vuetify.breakpoint.lgAndUp"
      :content-class="$vuetify.breakpoint.lgAndUp ? 'rounded-xl' : ''"
      transition="dialog-bottom-transition"
      :max-width="$vuetify.breakpoint.lgAndUp ? '600' : undefined"
      dark
      style="padding-top: env(safe-area-inset-top);"
    >
      <v-card :class="$vuetify.breakpoint.lgAndUp ? 'rounded-xl' : ''">
        <v-toolbar :color="template.colors.primary">
          <v-toolbar-title>
            {{ location.name }}
            {{ location.active ? 'bearbeiten' : 'verbinden' }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editLocation = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-tabs v-model="tabs" centered>
          <v-tab>Allgemein</v-tab>
          <v-tab :disabled="!step1valid">
            Verantwortliche Person
          </v-tab>
          <v-tab :disabled="!step1valid || !step2valid">
            Benachrichtigungen
          </v-tab>

          <v-tab-item>
            <v-row justify="center" v-if="location">
              <v-col cols="11">
                <v-switch
                  v-model="location.active"
                  inset
                  :label="
                    location.active
                      ? location.active == true
                        ? 'AKTIV'
                        : 'INAKTIV'
                      : 'INAKTIV'
                  "
                ></v-switch>
              </v-col>
              <v-col cols="11">
                <v-text-field
                  filled
                  rounded
                  label="Name"
                  hide-details
                  v-model="location.name"
                ></v-text-field>
              </v-col>
              <v-col cols="11">
                <v-text-field
                  filled
                  rounded
                  hide-details
                  label="Straße + Nr"
                  v-model="location.contact.address.street"
                ></v-text-field>
              </v-col>
              <v-col cols="11">
                <v-text-field
                  filled
                  rounded
                  hide-details
                  label="PLZ"
                  v-model="location.contact.address.zip"
                ></v-text-field>
              </v-col>
              <v-col cols="11">
                <v-text-field
                  filled
                  rounded
                  hide-details
                  label="Ort"
                  v-model="location.contact.address.city"
                ></v-text-field>
              </v-col>
              <v-col cols="11">
                <v-divider></v-divider>
              </v-col>

              <v-col cols="11" class="text-center">
                <v-btn
                  rounded
                  :disabled="!step1valid"
                  @click="tabs = 1"
                  :color="template.colors.primary"
                >
                  Weiter
                </v-btn>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row justify="center" v-if="location">
              <v-col cols="11">
                <v-text-field
                  filled
                  rounded
                  hide-details
                  label="Verantwortliche Person"
                  v-model="location.contact.representative"
                ></v-text-field>
              </v-col>
              <v-col cols="11">
                <v-text-field
                  filled
                  rounded
                  hide-details
                  label="E-Mail"
                  v-model="location.contact.email"
                ></v-text-field>
              </v-col>
              <v-col cols="11">
                <v-text-field
                  filled
                  rounded
                  hide-details
                  label="Telefon"
                  v-model="location.contact.phone"
                ></v-text-field>
              </v-col>
              <v-col cols="11">
                <v-divider></v-divider>
              </v-col>

              <v-col cols="11" class="text-center">
                <v-btn text @click="tabs = 0" class="mr-1">
                  Zurück
                </v-btn>
                <v-btn
                  class="ml-1"
                  rounded
                  @click="toStep3()"
                  :color="template.colors.primary"
                >
                  Weiter
                </v-btn>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row justify="center" v-if="location">
              <v-col
                cols="11"
                v-for="(notification, index) in location.notifications"
                :key="index"
              >
                <v-card class="rounded-xl px-4" light>
                  <v-row class="pa-2">
                    <v-col cols="auto" align-self="center">
                      <v-icon>
                        {{
                          notification.type == 'E-Mail' ? 'mdi-at' : 'mdi-phone'
                        }}
                      </v-icon>
                    </v-col>
                    <v-col cols="auto" align-self="center">
                      {{ notification.type }}
                    </v-col>
                    <v-col align-self="center" class="font-weight-bold">
                      {{ notification.name }}
                      <br />
                      <small class="font-weight-normal">
                        {{ notification.contact }}
                      </small>
                    </v-col>
                    <v-col cols="auto" align-self="center">
                      <v-btn
                        icon
                        @click="
                          editNotification == index
                            ? (editNotification = -1)
                            : (editNotification = index)
                        "
                      >
                        <v-icon v-if="editNotification != index">
                          mdi-pencil
                        </v-icon>
                        <v-icon v-if="editNotification == index">
                          mdi-check
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="auto"
                      align-self="center"
                      v-if="editNotification != index"
                    >
                      <v-btn icon @click="deleteNotification(index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    <v-row justify="center" v-if="editNotification == index">
                      <v-col cols="10">
                        <v-text-field
                          filled
                          rounded
                          hide-details
                          label="Name"
                          v-model="notification.name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          filled
                          rounded
                          hide-details
                          :label="
                            notification.type == 'E-Mail'
                              ? 'E-Mail-Adresse'
                              : 'Mobilnummer'
                          "
                          v-model="notification.contact"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-expand-transition>
                </v-card>
              </v-col>
              <v-col cols="11" class="text-center" v-if="!add_notification">
                <v-btn
                  rounded
                  :color="template.colors.success"
                  @click="add_notification = true"
                >
                  <v-icon>mdi-plus</v-icon>
                  Hinzufügen
                </v-btn>
              </v-col>
              <v-col cols="11" v-if="add_notification">
                <v-card class="rounded-xl pa-2" light>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        filled
                        rounded
                        hide-details
                        label="Art"
                        :items="types"
                        v-model="new_notification.type"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        filled
                        rounded
                        hide-details
                        label="Name"
                        v-model="new_notification.name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="new_notification.type">
                      <v-text-field
                        filled
                        rounded
                        hide-details
                        :label="
                          new_notification.type == 'E-Mail'
                            ? 'E-Mail-Adresse'
                            : new_notification.type == 'SMS'
                            ? 'Mobilnummer'
                            : ''
                        "
                        v-model="new_notification.contact"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <v-btn
                        rounded
                        text
                        :color="template.colors.success"
                        @click="addNotification()"
                      >
                        Speichern
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="11">
                <v-divider></v-divider>
              </v-col>

              <v-col cols="11" class="text-center">
                <v-btn text @click="tabs = 1" class="mr-1">
                  Zurück
                </v-btn>
                <v-btn
                  class="ml-1"
                  rounded
                  @click="saveLocation()"
                  :color="template.colors.primary"
                >
                  Speichern
                </v-btn>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'

export default {
  name: 'Anwesenheitsliste',
  data() {
    return {
      tabs: 0,
      editNotification: -1,
      add_notification: false,
      new_notification: {
        type: '',
        name: '',
        contact: '',
      },
      types: ['E-Mail', 'SMS'],
      benutzer: [],
      loading: false,
      datum: '',
      adatum: false,
      wizard: 0,
      newplace: false,
      locations: [],
      editLocation: false,
      location: '',
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
    step1valid() {
      return (
        this.location.name &&
        this.location.contact.address.street &&
        this.location.contact.address.zip &&
        this.location.contact.address.city
      )
    },
    step2valid() {
      return (
        this.location.contact.representative &&
        this.location.contact.email &&
        this.location.contact.phone
      )
    },
  },
  mounted() {
    this.load_locations()
  },
  methods: {
    toStep3() {
      if (
        this.location.contact.representative &&
        this.location.contact.email &&
        this.location.contact.phone
      ) {
        if (
          this.location.notifications
            ? this.location.notifications.length == 0
            : false
        ) {
          this.location.notifications.push({
            type: 'E-Mail',
            name: this.location.contact.representative,
            contact: this.location.contact.email,
          })
          this.location.notifications.push({
            type: 'SMS',
            name: this.location.contact.representative,
            contact: this.location.contact.phone,
          })
        }
        this.tabs = 2
      }
    },
    deleteNotification(id) {
      var notifications = this.location.notifications
      this.location.notifications = []
      notifications.forEach((n, index) => {
        if (index != id) {
          this.location.notifications.push(n)
        }
      })
    },
    addNotification() {
      this.location.notifications.push({
        type: this.new_notification.type,
        name: this.new_notification.name,
        contact: this.new_notification.contact,
      })
      this.new_notification = {
        type: '',
        name: '',
        contact: '',
      }
      this.add_notification = false
    },
    saveLocation() {
      if (this.location) {
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Locations')
          .doc(this.location.locationId)
          .set({
            active: this.location.active,
            id: this.location.locationId ? this.location.locationId : '',
            name: this.location.name ? this.location.name : '',
            contact: this.location.contact ? this.location.contact : '',
            notifications: this.location.notifications
              ? this.location.notifications
              : '',
            verband: this.template.var.verband,
          })
          .then(() => {
            this.editLocation = false
            this.location = ''
            this.load_locations()
          })
      }
    },
    addNull(val) {
      if (val > 10) {
        return val
      }
      return '0' + val
    },
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    createSportstaetten() {
      this.loading = true
      firebase.firestore().collection('User').doc(this.user.data.uid).update({
        sportstaetten: this.sportstaetten,
      })
    },
    load_locations() {
      this.locations = []
      if (
        this.user.data.sportstaetten
          ? this.user.data.sportstaetten.length == 0
          : true
      ) {
        this.locations.push({
          sportstaette: '',
          locationId: this.user.data.uid,
          name: this.user.data.verein.name,
          contact: {
            officialName: this.user.data.verein.name,
            representative: '',
            address: {
              street: '',
              zip: '',
              city: '',
            },
            phone: '',
            email: '',
          },
          notifications: [],
          active: false,
        })
      } else {
        this.user.data.sportstaetten.forEach((sportstaette, index) => {
          const id = this.user.data.uid + '-' + index
          firebase
            .firestore()
            .collection('User')
            .doc(this.user.data.uid)
            .collection('Locations')
            .doc(id)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const daten = doc.data()
                this.locations.push({
                  sportstaette: sportstaette,
                  locationId: doc.id,
                  name: daten.name,
                  contact: daten.contact,
                  notifications: daten.notifications,
                  active: daten.active,
                })
              } else {
                this.locations.push({
                  sportstaette: sportstaette,
                  locationId: id,
                  name: sportstaette.name,
                  contact: {
                    officialName: this.user.data.verein.name,
                    representative: '',
                    address: {
                      street: '',
                      zip: '',
                      city: '',
                    },
                    phone: '',
                    email: '',
                  },
                  notifications: [],
                  active: false,
                })
              }
            })
        })
      }
    },
  },
}
</script>
